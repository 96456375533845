@import "../../styles/vars.scss";

.file-label {
  display: block;
  width: 60vw;
  max-width: 300px;
  margin: 0 auto;
  background-color: $mix;
  border-radius: 2px;
  font-size: 1em;
  line-height: 2.5em;
  text-align: center;
  cursor: pointer;
  color: $light;
}

.file-input {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
