.footer {
  width: 80%;
  position: fixed;
  left: 10%;
  bottom: 2rem;
  text-align: center;

  a {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }
}
