// Override default variables before the import

@import "./styles/vars.scss";

$body-bg: $overlay;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "overlay": $overlay,
  "mix": $mix,
  "grey": $grey,
  "dark": $dark,
  "light": $light,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($primary, 0.2);
}

::-webkit-scrollbar-track {
  background: rgba($primary, 0.1);
}
